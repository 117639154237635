export function getAllowedClosedTags() {
  return [
    "div", // div
    "span", // span
    "p", // paragraph
    "ul", // unordered list
    "ol", // ordered list
    "li", // list item
    "font", // font
    "blockquote", // blockquote
    "b", // bold
    "strong", // strong
    "i", // italics
    "em", // emphasis
    "u", // underline
    "a", // anchor
  ];
}

export function getAllowedSelfClosingTags() {
  return [
    "br", // break
    "img", // image
  ];
}

export function getAllowedTags() {
  return [...getAllowedClosedTags(), ...getAllowedSelfClosingTags()];
}

export function getAllowedDataTextAsImageValues() {
  return [
    "First or Business Name",
    "Last Name",
    "Primary Phone Number",
    "Primary Contact First Name or Business Name",
    "Primary Contact Last Name",
  ];
}

export function getAllowedDataSizeValues() {
  return ["original", "small", "medium", "large"];
}

export function getAllowedDataIntegerSizeValues() {
  return ["1", "2", "3", "4", "5", "6", "7"];
}

export function getAllowedStyleFontSizeValues() {
  return ["10px", "12px", "14px", "16px", "18px", "24px", "30px"];
}

export function getAllowedStyleTextAlignValues() {
  return ["left", "center", "right"];
}

export function getAllowedImgStyleValues() {
  return {
    "max-width": ["96px", "200px", "420px", "500px"],
    "max-height": ["50px", "100px", "150px", "200px"],
  };
}

export function getAllowedBlockquoteStyleValues() {
  return {
    margin: ["0 0 0 40px"],
    border: ["none"],
    padding: ["0px"],
  };
}

export function getAllowedPStyleValues() {
  return {
    margin: ["0"],
  };
}

export function getAllowedOrderedListStyleValues() {
  return {
    padding: ["0 0 0 40px"],
  };
}

export function getAllowedUnorderedListStyleValues() {
  return {
    padding: ["0 0 0 40px"],
  };
}

export function getImgSrcUrlValues() {
  return {
    allowedProtocols: ["https"],
    allowedHostnames: [
      "cdn.canopytax.com",
      "cdn-stage.canopy.ninja",
      "cdn-integ.canopy.ninja",
      "canopy-staging.s3-us-west-2.amazonaws.com",
      "beanstalk-production.s3-us-west-2.amazonaws.com",
    ],
    restrictedStrings: ["data:", "text/html", "base64", "image/svg+xml"],
  };
}

export function getAHrefUrlValues() {
  return {
    allowedProtocols: ["https", "http", "mailto"],
    restrictedStrings: ["data:", "text/html", "base64"],
    hostnameRestrictedStrings: ["%", ":", "@"],
  };
}
